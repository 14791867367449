@tailwind base;
@tailwind components;
@tailwind utilities;



.flex-parent {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: flex-end;
  /* border: 3px solid #ff0000; */
  position: absolute;
  padding-top: 25;
}

.flex-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  z-index: 20;
  padding-right: 25;
  padding-top: 25;
  padding-right: 20px;
  /* border: 1px solid #ffffff; */
  position: absolute;
  width: 600px;
  height: 120px;
  flex-wrap: wrap;
  max-width: 400px;
  column-count: 3;
  column-gap: 10px;
  /* Optional: set the gap between columns */
}

.flex-3container {
  display: flex;
  /* border: 1px solid #ff9100; */
  width: 280px;
  height: 20px;
  margin-top: 10px;

}

.flex-child-element1 {
  flex: none;
  width: 70px;
  height: 20px;
  margin-top: 8px;
  /* border: 1px solid #00ffea;  */

}

.flex-child-element2 {
  flex: none;
  margin-left: 3px;
  padding: 3px;
  width: 200px;
  /* border: 1px solid #00ff40;  */
  margin-top: 12px;
}


.magnifying-glass-ui {
  position: absolute;
  z-index: 8;
  top: 20px;
  left: 10px;
}

@media screen and (min-width: 1000px) {
.popup-oval-image {
  align-items: center;
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 20px;
  border-radius: 50%;
  border: 5px rgb(255, 255, 255) solid;
  margin-top: 15px;
  margin-bottom: 50px;
 
}
}

@media screen and (max-width: 768px) {
  .popup-oval-image {
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 20px;
    border-radius: 50%;
    border: 5px rgb(255, 255, 255) solid;
    margin-top: 15px;
    margin-bottom: 50px;
   
  }
  }