/* .no-scroll, #no-scroll{
  overflow: hidden;
} */

.image-container {
  min-width: 100vw;
  /* Ensure a minimum width of the viewport */
  height: 100vh;
  overflow: hidden;

}

.image-wrapper {
  position: relative;
  width:100%;
  /* Natural width of the image */
  height: 100vh;
  

}

.image-wrapper img {
  height: 100%;
  /* Full height */
  width: 100%;
  /* Maintain natural width */
  display: block;
  /* Remove any inline spacing */

  object-fit: cover;

}

/* Media query for tablets and phones */
@media screen and (max-width: 768px) {

  .image-wrapper {
    position: relative;
    width: max-content !important;
    /* Natural width of the image */
    height: 100vh;
  }

  .image-container {
    width: 100%;
    height: 100%;
    overflow: visible;
    /* Allow overflow to be visible */
  }

  .image-container img {
    width: auto;
    /* Natural width of the image */
    height: 100%;
    /* Natural height of the image */
  }
}



.centered-text {
  position: absolute;
  top: 0;
  /* Align to the top */
  left: 0;
  /* Align to the left */
  color: white;
  /* Text color set to white */
  font-size: 2em;
  /* Adjust the size as needed */
  margin: 10px;
  /* Add some space from the edges */
  /* Add any additional styling for your text here */
}

.start-page-btn button {
  position: absolute;
  bottom: 64%;
  /* Adjust this to move it a little up from the center */
  left: 48%;
  transform: translateX(-55%);
  /* Adjust this to move it slightly to the left of the center */
  padding: 15px 20px;
  /* Increased padding for a larger button appearance */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 18px;
  /* Adjusted font size to match the button's increased size */
  display: flex;
  /* This will make the contents of the button align in a row */
  align-items: center;
  /* This will vertically center the text and the icon */
  justify-content: center;
  /* This will horizontally center the text and the icon */
  font-size: 1vh;
  padding: 1vh;
}