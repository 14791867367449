.overlay {
  position: fixed;       /* Make it fixed position */
  top: 0;                /* Start from the top */
  left: 0;               /* Start from the left */
  width: 100%;           /* Cover the full width */
  height: 100%;          /* Cover the full height */
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  z-index: 1000;     
}

.wide-grey-button {
    min-width: 300px; /* Minimum width of the button */
    width: auto; /* Automatically size based on content */
    background-color: rgba(128, 128, 128, 0.5); /* 50% transparent grey */
    color: white;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-bottom: 10px; /* Add space below each button */
}

/* Optionally, add a hover effect for better UX */
.wide-grey-button:hover {
    background-color: rgba(128, 128, 128, 0.7); /* Slightly darker on hover */
}


.content {
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: none;
    box-shadow: none;
    z-index: 10; 
}

.blinking-green-bold-caret {
    caret-color: limegreen;
    caret-width: thick; /* This makes the caret thicker/bolder */
    border: none; /* This removes the border */
    outline: none; /* This removes the focus outline; however, do note the accessibility concerns about removing outlines */
}

@keyframes shake {
    0% { transform: translate(5px, 5px) rotate(0deg); }
  10% { transform: translate(-5px, -10px) rotate(-1deg); }
  20% { transform: translate(-10px, 0px) rotate(1deg); }
  30% { transform: translate(0px, 10px) rotate(0deg); }
  40% { transform: translate(5px, -5px) rotate(1deg); }
  50% { transform: translate(-5px, 10px) rotate(-1deg); }
  60% { transform: translate(-10px, 5px) rotate(0deg); }
  70% { transform: translate(10px, 0px) rotate(-1deg); }
  80% { transform: translate(-5px, -10px) rotate(1deg); }
  90% { transform: translate(5px, 0px) rotate(0deg); }
  100% { transform: translate(0px, 5px) rotate(-1deg); }

}

.shake {
  animation: shake 0.3s;
  animation-iteration-count: 1;
}

.red-flash {
  background-color: rgba(255, 0, 0, 0.7) !important;
  transition: background-color 0.3s !important;
}

  