.overAll {
   
    z-index: 1050; 
    position: relative;
}

@keyframes bravoAnimation {
    from {
      transform: translateY(-40px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes charAppear {
    from {
      transform: translateX(-10px);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
  
  .bravo {
    animation: bravoAnimation 1s ease-in-out forwards;
  }
  
  .char-animation {
    display: inline-block;
    transform: translateX(-10px);
    opacity: 0;
    animation: charAppear 0.2s ease-in-out forwards;
  }
  
  .plus-symbol {
    display: inline-block;
    transform: translateX(-10px);
    opacity: 0;
    animation: charAppear 0.2s ease-in-out forwards;
    animation-delay: 1s; /* After Bravo! animation finishes */
  }
  
  .pts-symbol {
    display: inline-block;
    transform: translateX(-10px);
    opacity: 0;
    animation: charAppear 0.5s ease-in-out forwards;
    animation-delay: calc(1s + 0.2s * 3); /* After all the score characters animate */
  }
  
  @keyframes barsAnimation {
    from {
      transform: translateY(40px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  .bars{
    animation: barsAnimation 1s ease-in-out forwards;
  }

  .p-8.rounded-md.shadow-xl.text-red-500.bg-white {
    display: flex;
    flex-direction: column;  /* Makes it a vertical flexbox */
    transition: max-height 1s ease-in-out;
}

.email-input-container {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}

.show-email {
  opacity: 1;
  visibility: visible;
}


  
  
  