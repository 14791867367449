.no-scroll, #no-scroll {
  overflow: hidden;
}

h1 {
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}

h2 {
  font-size: 250px;
  text-align: center;
  color: #ffffff;
  font-weight: bold;
}

.parent-container {
  display: flex;
  justify-content: center;
  /* Center the buttons */
}

button.textbox-button {
  display: flex;
  width: auto;
  justify-content: space-evenly;
  align-content: center;
  background-color: rgb(156, 156, 156);
  color: #333;
  font-size: 15px;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.2);
  margin-top: 10px;

}

.p {
  position: absolute;
  z-index: 9;
  font-size: 25px;
  text-align: center;
  color: #ffffff;
  top: 12px;
  left: 230px;
}

.input {
  align-items: center;
  background: none;
  border: none;
}

.icon {
  position: absolute;
  width: 50px;
  height: 50px;
  transform-style: preserve-3d;
  transition: transform 1s ease-in-out;
  font-size: 1vw;
  padding: 1vw;
}

.screen-shake {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: red;
  /* background-color: red !important; */
  animation-name: shake;
  animation-duration: 0.5s;
  animation-timing-function: cubic-bezier(0.36, 0.07, 0.19, 0.97);
  animation-iteration-count: 1;
  z-index: 99999;
}

@keyframes shake {

  10%,
  90% {
    transform: translate3d(-5px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(10px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-20px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(20px, 0, 0);
  }
}

.progress-bar {
  height: 10px;
  width: 200px;
  background-image: linear-gradient(to right, rgba(0, 255, 255, 0.3) 0%, rgba(0, 255, 255, 0.3) 100%);
  animation: fill-progress 2s ease-in-out forwards;
}

@keyframes fill-progress {
  0% {
    width: 0%;
  }

  100% {
    width: var(--soft-actual-point, 0%);
  }
}

element.style {
  position: absolute;
  left: 36.48px;
  top: 104.821px;
  width: 92.352px;
  height: 356.049px;
}

.to-left-top {
  position: fixed;
  top: 0;
  left: 0;
  height: 50px;
  width: 300px;
}

@keyframes colorPulse {
  0%, 100% {
    fill: #ff0000;
    /* red-500 */
    opacity: 0.5;
  }

  12.5% {
    fill: #ff00bf;
    /* red-500 */
    opacity: 1;
  }

  25% {
    fill: #6200ff;
    /* yellow-500 */
    opacity: 0.5;
  }

  37.5% {
    fill: #0066ff;
    /* yellow-500 */
    opacity: 1;
  }

  50% {
    fill: #00d9ff;
    /* green-500 */
    opacity: 0.5;
  }

  62.5% {
    fill: #00ff80;
    /* green-500 */
    opacity: 1;
  }

  75% {
    fill: #fbff00;
    /* blue-500 */
    opacity: 0.5;
  }

  87.5% {
    fill: #ffae00;
    /* blue-500 */
    opacity: 1;
  }
}

@media screen and (min-width: 1000px) {
.aspect-ratio-wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
  /* For a 16:9 aspect ratio */
}

.background-image-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.aspect-ratio-image {
  object-fit: cover;
  object-position: center;
  width: 100%;
  height: 100%;
}
}
.button-container {
  position: absolute;
  bottom: 10%;
  right: 0%;
  transform: translate(-55%, -55%);
  /* Adjust these values as needed. 55% moves it up and left from the center. */
}

.button {
  padding: 1em 2em;
  /* This provides a larger clickable area. Adjust as needed. */
  /* other button styles... */
}

@media screen and (max-width: 768px) {
  .aspect-ratio-wrapper {
    position: relative;
    width: max-content !important;
  }

  .background-image-container {
    width: 100%;
    height: 100%;
    overflow: visible;
    /* Allow overflow to be visible */
  }

  .aspect-ratio-image {
    width: auto;
    /* Natural width of the image */
    height: 100vh;
    /* Natural height of the image */
  }
}